import apiConfig from '../../../config/api/apiConfig';
import httpService from '../../httpService';
import { getCommerceHeaders } from '../../tenantHelper';

const deleteFromListCommerce = async ({ appCtx, listId, listLineItemId, tagName }) => {
  const { regionCode } = appCtx;

  const additionalHeaders = getCommerceHeaders(regionCode, true, appCtx.url, appCtx.store);

  const url = new URL(apiConfig.deleteFromListCommerceUrl(listId, listLineItemId, regionCode));

  const response = await httpService().delete(url, { headers: additionalHeaders }, 'json');

  const defaultResponse = {
    tagName,
  };
  if (response.statusCode === 204) {
    return { ...defaultResponse, data: response, ok: true };
  }

  return {
    ...defaultResponse,
    ok: false,
    error_code: 'UNKOWN',
  };
};

export default deleteFromListCommerce;
