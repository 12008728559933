import React, { useState, useEffect, useContext } from 'react';
import createContainer from 'constate';
import { usePdpTags } from '../external_dependencies/analytic/tags/pdp';
import constants from '../config/constants';

function useDigitalDataContext() {
  const [page, setPage] = useState('');
  const [shouldUpdateDigitalData, setShouldUpdateDigitalData] = useState(false);
  const pdpTags = usePdpTags();

  function init() {
    if (!window.digitalData) {
      window.digitalData = {};
    }
    if (!window.digitalData.tag) {
      Object.assign(window.digitalData, { tag: {} });
    }
    setShouldUpdateDigitalData(true);
  }

  const digitalData = {
    pdp: pdpTags,
    setPage,
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (shouldUpdateDigitalData && page === constants.PAGES.PDP && pdpTags.updateCounter > 0) {
      pdpTags.addTags();
    }
  }, [page, shouldUpdateDigitalData, pdpTags.updateCounter]);

  return { digitalData };
}

const DigitalDataContext = createContainer(useDigitalDataContext);

const withDigitalData = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithConfig = (props) => {
    const digitalData = useContext(DigitalDataContext.Context);
    return <WrappedComponent {...props} {...digitalData} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithConfig.getInitialProps = getInitialProps;
  }
  return ComponentWithConfig;
};

export default DigitalDataContext;
export { withDigitalData };
