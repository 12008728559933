import { createScriptTag, appendScriptsToHead } from '../../utils/DOMUtil';
import constants from '../../config/constants';

const loadFitAnalyticsScript = () => {
  const dynamicScript = createScriptTag({
    src: constants.FIT_ANALYTICS,
    async: true,
  });
  appendScriptsToHead(dynamicScript);
};

export default loadFitAnalyticsScript;
