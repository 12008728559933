import createContainer from 'constate';
import constants from '../../config/constants';
import WithRouter from '../../utils/WithRouter';

const LanguageContext = createContainer(
  () => {
    const language = WithRouter(constants.lang) || 'cl';
    // eslint-disable-next-line
    const translations = require(`../../config/translations/labels-${language}`);
    return translations;
  },
  () => []
);

export { LanguageContext };
