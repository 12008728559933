import { getCommerceHeaders as storeCommerceHeaders } from '@digital-retail/store-manager';
import { getPageName, getSidCookie, isMarketPlace } from './urlHelper';

const getCommerceHeaders = (regionCode, includeAuth, uri, store) => {
  const mkp = isMarketPlace();
  const h = storeCommerceHeaders({ store, regionCode, isMarketPlace: mkp });

  const sid = getSidCookie();
  const headers = {
    'x-channel-id': 'WEB',
    'x-commerce': 'FCM',
    'x-origin-name': 'XLP',
    'x-origin-view': getPageName(uri),
    ...h,
  };
  if (sid && includeAuth) {
    headers.Authorization = sid;
  }
  return headers;
};

export { getCommerceHeaders };
