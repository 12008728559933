import { isStandalone } from '@digital-retail/store-manager';
import { storeMap } from '../config/storeMap';
import CookieStorage from './CookieStorage';

const getLocation = (uri) => {
  const regex = new RegExp(
    [
      '^(https?:)//', // protocol
      '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
      '(/{0,1}[^?#]*)', // pathname
      '(\\?[^#]*|)', // search
      '(#.*|)$', // hash
    ].join('')
  );
  const match = uri.match(regex);
  return (
    match && {
      href: uri,
      protocol: match[1].replace(':', ''),
      host: match[2],
      hostname: match[3],
      port: match[4] || '80',
      pathname: match[5] || '/',
      search: match[6],
      hash: match[7],
    }
  );
};

const getPage = (uri) => {
  const errorPage = document.getElementsByClassName('error-page');
  if (errorPage.length > 0) {
    return 'error';
  }
  const path = getLocation(uri);
  const paths = path.pathname.split('/');
  return paths.length > 2 ? paths[2] : '';
};

const parseQueryString = (url) => {
  const params = {};
  if (url.indexOf('?') === -1) {
    return params;
  }
  url
    .substring(url.indexOf('?') + 1)
    .split('&')
    .filter((param) => param)
    .filter((p) => !!p)
    .forEach((param) => {
      const [name = '', value = ''] = param.split('=');
      if (name) {
        params[decodeURIComponent(name)] = decodeURIComponent(value.replace(/\+/g, '%20')) || '';
      }
    });

  return params;
};

const serializeQuery = (params = {}, ignoreQuery = []) => {
  return Object.keys(params)
    .filter((name) => ignoreQuery.indexOf(name) === -1)
    .map((name) => {
      if (params[name]) {
        const value = encodeURIComponent(params[name]);
        return `${encodeURIComponent(name)}=${value.replace(/(%20)+/g, '+')}`;
      }
      return encodeURIComponent(name);
    })
    .join('&');
};

const getStoreValue = (seller) => {
  return storeMap[seller] || 'falabella';
};

const getCtxStoreValue = (seller) => {
  return storeMap[seller] ? seller.toLowerCase() : 'falabella';
};

const getPaginatedUrl = (currentUrl, pageIndex, store = null) => {
  let baseURL = currentUrl.split('?')[0];
  const params = parseQueryString(currentUrl);
  params.page = pageIndex;
  if (store) {
    if (isStandalone({ store })) {
      ['store', 'exp', 'subdomain', 'domain'].forEach((e) => delete params[e]);
    }
    params.store = store;
    const sellerTenant = getStoreValue(store);
    baseURL = baseURL.replace('falabella', sellerTenant);
  }
  const qString = serializeQuery(params);
  return qString ? `${baseURL}?${serializeQuery(params)}` : `${baseURL}`;
};

const isMarketPlace = () => {
  const experience = CookieStorage.getCookie('seller_experience');
  return experience === 'MARKETPLACE';
};

const getSidCookie = () => {
  return CookieStorage.getCookie('sid');
};

const getRequestUrl = (reqUrl, tenant) => {
  const { site = tenant, store, ...rest } = parseQueryString(reqUrl);
  const baseURL = `/${site}${reqUrl.split('?')[0]}`;
  const qString = serializeQuery(rest);
  return qString ? `${baseURL}?${qString}` : `${baseURL}`;
};

function getCanonicalText(str) {
  return encodeURIComponent(str);
}

const getPageName = (uri = '') => {
  const pageNameMapping = {
    '/search?': 'Search',
    '/buscar?': 'Search',
    '/category/': 'Category',
    '/lista/': 'Category',
    '/seller/': 'Seller',
    '/vendedor/': 'Seller',
    '/brand/': 'Brand',
    '/marca/': 'Brand',
    '/collection/': 'Collection',
    '/seleccion/': 'Collection',
  };
  const page = Object.keys(pageNameMapping).find((p) => uri.includes(p));
  return pageNameMapping[page] || 'XLP';
};

const sodimacSisToSoComUrl = (sisUrl) => {
  const urlDomainToReplaceCL = {
    staging: '//staging-sodimac.falabella.com',
    beta: '//beta-sodimac.falabella.com',
    www: '//sodimac.falabella.com',
  };
  const urlDomainToReplacePE = {
    staging: '//staging-sodimac.falabella.com.pe',
    beta: '//beta-sodimac.falabella.com.pe',
    www: '//sodimac.falabella.com.pe',
  };
  let soComUrl = sisUrl;
  Object.entries(urlDomainToReplaceCL).forEach(([key, value]) => {
    if (sisUrl.includes(value)) {
      soComUrl = sisUrl.replace(new RegExp(value, 'g'), `//${key}.sodimac.cl`);
    }
  });
  Object.entries(urlDomainToReplacePE).forEach(([key, value]) => {
    if (sisUrl.includes(value)) {
      soComUrl = sisUrl.replace(new RegExp(value, 'g'), `//${key}.sodimac.com.pe`);
    }
  });
  return soComUrl
    .replace(/\/category\//g, '/lista/')
    .replace(/\/collection\//g, '/seleccion/')
    .replace(/\/brand\//g, '/marca/')
    .replace(/\/seller\//g, '/vendedor/')
    .replace(/\/search?/g, '/buscar?')
    .replace(/\/product\//g, '/articulo/');
};

const tottusSisToSoComUrl = (sisUrl) => {
  const urlDomainToReplaceCL = {
    staging: '//staging-tottus.falabella.com',
    beta: '//beta-tottus.falabella.com',
    www: '//tottus.falabella.com',
  };
  const urlDomainToReplacePE = {
    staging: '//staging-tottus.falabella.com.pe',
    beta: '//beta-tottus.falabella.com.pe',
    www: '//tottus.falabella.com.pe',
  };
  let toComUrl = sisUrl;
  Object.entries(urlDomainToReplaceCL).forEach(([key, value]) => {
    if (sisUrl.includes(value)) {
      toComUrl = sisUrl.replace(new RegExp(value, 'g'), `//${key}.tottus.cl`);
    }
  });
  Object.entries(urlDomainToReplacePE).forEach(([key, value]) => {
    if (sisUrl.includes(value)) {
      toComUrl = sisUrl.replace(new RegExp(value, 'g'), `//${key}.tottus.com.pe`);
    }
  });
  return toComUrl
    .replace(/\/category\//g, '/lista/')
    .replace(/\/collection\//g, '/seleccion/')
    .replace(/\/brand\//g, '/marca/')
    .replace(/\/seller\//g, '/vendedor/')
    .replace(/\/search?/g, '/buscar?')
    .replace(/\/product\//g, '/articulo/');
};

export {
  getCanonicalText,
  getCtxStoreValue,
  getPage,
  getPageName,
  getPaginatedUrl,
  getRequestUrl,
  getSidCookie,
  getStoreValue,
  isMarketPlace,
  sodimacSisToSoComUrl,
  tottusSisToSoComUrl,
};
