import getConfig from 'next/config';
import Router from 'next/router';
import constants from '../../config/constants';
import { appendScriptsToHead, createScriptTag } from '../../utils/DOMUtil';
import WithUrl from '../../utils/WithUrl';
import { getPage } from '../../utils/urlHelper';

const nextConfig = getConfig();
const { env } = (nextConfig && nextConfig.publicRuntimeConfig) || {
  env: 'www',
};

const typeMapping = {
  noResult: 'OTHER',
  search: 'OTHER',
  category: 'CATEGORY',
  collection: 'OTHER',
  '': 'HOMEPAGE',
  buscar: 'OTHER',
  lista: 'CATEGORY',
  seleccion: 'OTHER',
};

const getCategoryId = () => {
  const { pathname } = window.location;
  const paths = pathname.split('/');
  return paths.length > 3 ? paths[3] : '';
};

const getVariantId = () => {
  const { pathname } = window.location;
  const paths = pathname.split('/');
  return paths.length > 5 ? paths[5] : '';
};

const pageDataFunctionMapping = {
  CATEGORY: () => {
    return [getCategoryId()];
  },
  PRODUCT: () => {
    return [getVariantId()];
  },
};

const getType = (page) => {
  return typeMapping[page] || '';
};

const getData = (pageType) => {
  const pageDataFunction = pageDataFunctionMapping[pageType];
  return pageDataFunction ? pageDataFunction() : null;
};

const getContext = () => {
  const page = getPage(window.location.href);
  const pageType = getType(page);
  const context = { type: pageType };
  const data = getData(pageType);
  if (data) {
    context.data = data;
  }
  return context;
};

const setContext = () => {
  window.DY = window.DY || {};
  window.DY.recommendationContext = getContext();
};

const updateContext = () => {
  window.DY.API('spa', {
    context: getContext(),
    url: window.location.href,
    countAsPageview: true,
  });
};

const attachOnUpdateContext = () => {
  Router.events.on('routeChangeComplete', () => {
    updateContext();
  });
};

const loadScripts = (tenant) => {
  const dynamicYieldId = constants.DY_ID[env] && constants.DY_ID[env][tenant];

  const dynamicApiUrl = WithUrl('DY_DYNAMIC_API', {
    custom: { DY_ID: dynamicYieldId },
  });
  const staticApiUrl = WithUrl('DY_STATIC_API', {
    custom: { DY_ID: dynamicYieldId },
  });

  const dynamicScript = createScriptTag({
    src: dynamicApiUrl,
  });
  const staticScript = createScriptTag({
    src: staticApiUrl,
  });
  appendScriptsToHead(dynamicScript);
  appendScriptsToHead(staticScript);
};

const loadDynamicYield = ({ tenant }) => {
  setContext();
  loadScripts(tenant);
  attachOnUpdateContext();
};

export { loadDynamicYield };
