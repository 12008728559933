const getItem = ({ name, fromPersistence }) => {
  return fromPersistence ? localStorage.getItem(name) : sessionStorage.getItem(name);
};

const setItem = ({ name, value, shouldPersist }) => {
  return shouldPersist ? localStorage.setItem(name, value) : sessionStorage.setItem(name, value);
};

const WebStorage = {
  getItem,
  setItem,
  removeItem: ({ name, fromPersistence }) => {
    return fromPersistence ? localStorage.removeItem(name) : sessionStorage.removeItem(name);
  },
  getItemWithTTL: ({ name, fromPersistence }) => {
    const data = getItem({ name, fromPersistence });
    if (data) {
      const { ttl, value } = JSON.parse(data);
      const now = new Date().getTime();

      if (ttl > now) {
        return value;
      }
    }
    return undefined;
  },
  setItemWithTTL: ({ name, value, fromPersistence, ttl = 0 }) => {
    const now = new Date().getTime();
    const data = {
      ttl: now + ttl,
      value,
    };

    setItem({ name, value: JSON.stringify(data), fromPersistence });
  },
};

export default WebStorage;
