import 'core-js/es/array/find';
import 'core-js/es/array/includes';
import 'core-js/es/array/keys';
import 'core-js/es/string/includes';
import 'core-js/stable/atob';

const decodeOverride = (scope) => {
  const originalDecodeComponent = decodeURIComponent;
  // eslint-disable-next-line no-param-reassign
  scope.decodeURIComponent = (url) => {
    try {
      return originalDecodeComponent(url);
    } catch {
      return unescape(url);
    }
  };
};
if (process.browser) {
  decodeOverride(window);
} else {
  decodeOverride(global);
}
