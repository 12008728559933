import createContainer from 'constate';
import React, { useContext, useEffect, useState } from 'react';
import {
  addToList as addToListCommerce,
  deleteFromList as deleteFromListCommerce,
  getAllLists as getAllListsCommerce,
} from './cart/myLists';
import { nameHOC } from './hocutils';
import _ from './LodashImports';
import UserContext from './UserContext';

function useMyListsContext({ data: initialData, appCtx }) {
  const isMyListsEnabled = _.get(appCtx, 'siteConfig.toggles.isMyListsEnabled', false);
  const userContext = useContext(UserContext.Context);
  const [myListData, setMyListData] = useState(initialData || []);
  const [error, setError] = useState('');
  const [showMyLists, setShowMyLists] = useState(false);
  const [addedLists, setAddedLists] = useState([]);
  const [undo, setUndo] = useState();
  const [alertType, setAlertType] = useState('success');

  const getAllLists = async ({ listType }) => {
    const response = await getAllListsCommerce({
      appCtx,
      listType,
    });
    if (response.ok) {
      setShowMyLists(true);
      setMyListData(response.data.lists || []);
    } else {
      setError(response.error_code || 'error');
    }
    return response;
  };

  useEffect(() => {
    if (isMyListsEnabled && userContext.isUserLoggedIn) getAllLists({});
  }, [userContext.isUserLoggedIn]);

  const addTolList = async ({ listType, tagName, product }) => {
    return addToListCommerce({
      tagName,
      listType,
      product,
      appCtx,
    });
  };

  const deleteFromList = async (listId, listLineItemId, tagName) => {
    return deleteFromListCommerce({ appCtx, listId, listLineItemId, tagName });
  };
  return {
    myLists: {
      myListData,
      addTolList,
      setMyListData,
      getAllLists,
      deleteFromList,
      error,
      setAddedLists,
      addedLists,
      setUndo,
      undo,
      alertType,
      setAlertType,
      showMyLists,
    },
  };
}

const MyListContext = createContainer(useMyListsContext);

const withMyLists = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithMyLists = (props) => {
    const { myLists } = useContext(MyListContext.Context);
    return <WrappedComponent {...props} myLists={myLists} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithMyLists.getInitialProps = getInitialProps;
  }
  ComponentWithMyLists.originalName = nameHOC(WrappedComponent);
  ComponentWithMyLists.displayName = nameHOC(WrappedComponent, 'WithMyListsContext');

  return ComponentWithMyLists;
};
export default MyListContext;
export { withMyLists };
