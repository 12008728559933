export const pe = {
  colorGroupMapping: {
    AMARILLO: '#fdec6f',
    AZUL: '#0b108c',
    'AZUL CLARO': '#94bfdc',
    BEIGE: '#cfb174',
    BLANCO: '#ffffff',
    BURDEO: '#5f021f',
    CAFÉ: '#8e5b3e',
    CELESTE: '#94bfdc',
    CREMA: '#fff8d4',
    FUCSIA: '#d90073',
    GRIS: '#dddddd',
    MORADO: '#6d165a',
    NARANJO: '#fc930a',
    NEGRO: '#000000',
    ROJO: '#ba2d1e',
    ROSADO: '#fcd9e5',
    VERDE: '#519548',
  },
  configurations: {
    adsConfig: {
      adProviderUrl:
        'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt3ef14bb68a88d66c/ads_desktop_v3.html',
      xlp: [
        {
          slot: 'div-gpt-ad-1671544221138-0',
          folder: '/21632469435/fape_plp_banner_superior',
          container: 'google-banner-superior-div',
        },
        {
          slot: 'div-gpt-ad-1671544091827-0',
          folder: '/21632469435/fape_plp_vertical',
          container: 'google-banner-izq-div',
        },
        {
          slot: 'div-gpt-ad-1655991534231-0',
          folder: '/21632469435/fape_plp_horizontal_1',
          container: 'google-banner-inferior-desktop-div',
        },
      ],
      search: [
        {
          slot: 'div-gpt-ad-1671544221138-0',
          folder: '/21632469435/fape_plp_banner_superior',
          container: 'google-banner-superior-div',
        },
        {
          slot: 'div-gpt-ad-1671544091827-0',
          folder: '/21632469435/fape_plp_vertical',
          container: 'google-banner-izq-div',
        },
        {
          slot: 'div-gpt-ad-1655991534231-0',
          folder: '/21632469435/fape_plp_horizontal_1',
          container: 'google-banner-inferior-desktop-div',
        },
      ],
    },
    disabledStoresSponsoredProducts: { stores: ['tottus'] },
    A2C_ERRORS: {
      DEFAULT: 'Tuvimos un problema y no logramos agregar tus productos al carro. Por favor inténtalo nuevamente.',
      AL022:
        'Superaste la cantidad máxima de unidades permitidas para productos de este vendedor en el Carro. Por favor, ajusta la cantidad antes de proceder',
      AL023:
        'Has superado la cantidad máxima de unidades permitidas para este producto en el carrito. Por favor, ajusta la cantidad antes de proceder.',
    },
  },
  endpoints: {
    APERTURA_URL_INVALID_CMR: 'https://www.falabella.com.pe/falabella-pe/page/CMR-enlinea?=&detalleClic=pdpsinou',
    APERTURA_URL_VALID_CMR: 'https://www.falabella.com.pe/falabella-pe/page/CMR-enlinea?detalleClic=pdpconou',
    ATG_AUTH_BASEURL: 'https://www.falabella.com.pe',
    CATALYST_AUTH_BASEURL: 'https://www.falabella.com.pe',
    INTERNATIONAL_SHIPPING_LINK: 'https://www.falabella.com.pe/falabella-pe/page/compra-internacional',
    RETURN_POLICY_LINK_URL: 'https://www.falabella.com.pe/falabella-pe/page/devoluciones-y-cambios',
    SERVICES_PE_FALABELLA: '',
    SERVICES_PE_SODIMAC: 'https://sodimac.falabella.com.pe/sodimac-pe/page/servicios-hogar',
    SERVICES_PE_TOTTUS: '',
    WISHLISTS_PAGE_URI: 'https://www.falabella.com.pe/falabella-pe/myaccount/myLists',
  },
  facetLabels: {
    'f.derived.variant.activeEvent': '<p>2DO A S/.1</p>',
    'f.derived.variant.freeShipping': '<p>En productos pequeños desde&nbsp;<strong>S/ 99</strong></p>',
  },
  textDictionary: {
    A11Y_LOCATION_LABEL: 'location icon',
    A11Y_USER_LABEL: 'user info',
    A11Y_VIDEO_LABEL: 'Play Video',
    A2C_CONTINUE_SHOPPING: 'Seguir comprando',
    A2C_ERROR_AL013: 'No se pudo agregar el ID de producto',
    A2C_ERROR_CART_CONFIG_BREACH: 'Se superó la cantidad máxima permitida por producto',
    A2C_ERROR_REMOVE_ITEM: 'Error al eliminar el artículo del carrito',
    A2C_EXT_WARRANTY: 'Ver beneficios',
    A2C_VIEW_BASKET: 'IR AL CARRO DE COMPRAS',
    AB_TEST_MATCH_VALUE: 'rltr_enabled',
    AB_TEST_VALUE_OVERRIDE: 'bi_ab__online_ltr_classifier',
    ACCEPT: 'ACEPTAR',
    ACCUMULATE: 'Acumula hasta',
    ACTIVATE_ADD_TO_BAG: 'HABILITAR AGREGAR AL CARRO',
    ADD_REBRANDING: 'Agregar',
    ADD_TO_BAG: 'AGREGAR AL CARRO',
    ADD_TO_CART: 'Ver producto',
    ADD_TO_CART_TEXT: 'Agregar al Carro',
    ADD_TO_CART_TEXT_ALONE: 'Agregar',
    ADD_TO_CART_TEXT_MKP: 'Agregar al Carro',
    ADD_TO_CART_TEXT_REBRANDING: 'Agregar al Carro',
    ADD_X_ITEM_TO_BAG: 'AGREGAR LOS {count} A LA BOLSA',
    ADDING_TO_CART: 'Agregando...',
    ADDING_TO_CART_1: 'Agregando',
    ADDITIONAL_SERVICES_LABEL: 'Servicios adicionales',
    ANDROID_FACET_SUPPORT_VERSION: '10.0.0',
    ASSOCIATED_SELLER_INFO:
      'Este producto es propiedad de un vendedor asociado y distribuido por Falabella. Si quieres vender en el portal de Falabella, entra a {MARKETPLACE_URL}, regístrate y publica tus productos.',
    ATG_AUTH_BASEURL: 'https://www.falabella.com',
    AVAILABILITY: 'Disponibilidad',
    AVAILABILITY_DELIVERY_TYPE: 'Tipo de entrega',
    AVAILABILITY_DOWNLOADABLE: 'Descargable',
    AVAILABILITY_IMMEDIATE_DELIVERY: 'Entrega inmediata',
    AVAILABILITY_PDP: 'Disponibilidad de producto',
    AVAILABILITY_SELECT_OPTION: 'Selecciona opción para ver disponibilidad',
    BAG: 'Bolsa',
    BIG_TICKETS_UNAVAILABLE:
      'Debido a su tamaño, este producto no está disponible para retiro en tienda, pero nosotros te lo llevamos sin problemas, intenta la opción',
    BOOM_SHIPPING: 'Boom',
    BUNDLED_PRODUCT_LABEL: 'Excelente cuando se compran juntos',
    BUNDLED_PRODUCT_TOTAL_PRICE_LABEL: 'Llévalos por',
    BV_RECOMMENDED_THRESHOLD: 80,
    CANCEL: 'Cancelar',
    CART_ASSEMBLY_OPTION_SEC_LABEL: '(condiciones y coberturas)',
    CATALYST_AUTH_BASEURL: 'https://www.falabella.com',
    CATEGORIES: 'Categorías',
    CATEGORY_LATERAL_TEXT: `<p>%Category Name%</p>
    <p>Los productos de %Category Name% que necesitas para el mejoramiento de tu hogar están en Sodimac Perú a un precio increíble. Tenemos una gran variedad de opciones y modelos que se adecúan a cada uno de los espacios que desees renovar.</p>
    <p>Visita nuestra categoría %Category Name% y selecciona el producto del material, medida, color y demás características que prefieras. Recuerda que solo en Sodimac Perú contamos con todo lo necesario para cada uno de tus proyectos.</p>`,
    CHAT_BOT: 'ChatBot',
    CHAT_BOT_NAME_AR: 'Lucía',
    CHOOSE_LOCATION: 'Selecciona tu región',
    CHOOSE_OPTION: 'Selecciona una opción',
    CHOOSE_OPTION_LABEL: 'Opción rango de horario:',
    CHOOSE_VARIANTS: 'ELIGE TUS OPCIONES',
    CIUDAD: 'Provincia',
    CIUDAD_TEXT: 'Provincia',
    CLEAN: 'Limpiar Filtro',
    CLEAR: 'Cerrar',
    CLEAR_FACET_VALUES_MODAL: 'Limpiar filtros',
    CLEAR_FILTERS: 'Quitar filtros',
    CLICK_TO_GET_CALL_BUTTON: '¿NECESITAS ASESORÍA?',
    CLICK_TO_GET_CALL_BUTTON_SUBTITLE: '¡Te llamamos! Haz click',
    CLOSE_A11Y_LABEL: 'Close',
    CMR_CALCULATOR_ERROR_LOADING_TRY_AGAIN: 'Error al cargar. Intenta nuevamente.',
    CMR_INSTALLMENT_ERROR_MESSAGE: 'Error al cargar. Intenta nuevamente.',
    CMR_POINTS: 'CMR Puntos',
    CMR_REMINDER: 'Planes disponibles solo con tu tarjeta CMR',
    CMRAPERTURATEXT_ALREADY_HAVE: 'Pídela y obtén S/40 de regalo.',
    CMRAPERTURATEXT_BENEFITS: 'Pídela aquí',
    CMRAPERTURATEXT_DONT_HAVE_IT: '¿Aún no la tienes?',
    CMRAPERTURATEXT_ONTHIS_PURCHASE: 'en esta compra',
    CMRAPERTURATEXT_OPEN_IT: ' Pídela aquí',
    CMRAPERTURATEXT_OPEN_YOUR_CMR: '¿Aún no tienes tu tarjeta CMR?',
    CMRAPERTURATEXT_REQUEST: '¡Compra con tu CMR Visa y acumula CMR Puntos!',
    CMRAPERTURATEXT_SAVE: 'Ahorra',
    CMRAPERTURATEXT_USING_CMR: 'con tu CMR',
    CMRONLINEDECLINED: 'No se pudo terminar la solicitud. Para mas información dirígete a una sucursal CMR',
    CMRONLINESUCCESS: 'Tu Tarjeta CMR fue añadida a tus medios de pago.',
    CMRONLINESUCCESSNOTENROLLED:
      'Tu tarjeta CMR fue creada, pero no pudo ser anadida a tu cuenta, favor dirígete a una sucursal CMR',
    CMSIZE: 'cm',
    COLOR: 'Color',
    COLORS_AVAILABLE: 'COLORES DISPONIBLES',
    COMBO_DEFAULT_LABEL: 'Selecciona',
    COMBO_GIGAS_LABEL: 'Gigas',
    COMBO_MINUTES_LABEL: 'Minutos',
    COMBO_ORDER_BY_LABEL: 'Ordenar por',
    COMBO_PHONE_PRICE_LABEL: 'Precio de equipo',
    COMBO_PLAN_PRICE_LABEL: 'Precio de plan',
    COMUNA: 'Distrito',
    CONNECT_ASSESSMENT_FORM_ACCEPT: 'Acepto los ',
    CONNECT_ASSESSMENT_FORM_ACCEPT_BUTTON: 'QUIERO EVALUARME',
    CONNECT_ASSESSMENT_FORM_OPTION_KEEP_NUMBER: 'Mantener número',
    CONNECT_ASSESSMENT_FORM_OPTION_NEW_NUMBER: 'Número nuevo',
    CONNECT_ASSESSMENT_FORM_QUE_ES_ESTO: '¿Qué es esto?',
    CONNECT_ASSESSMENT_FORM_QUE_QUIERES_HACER: '¿QUÉ QUIERES HACER CON TU NÚMERO?',
    CONNECT_ASSESSMENT_FORM_RETRY_BUTTON_REBRANDING: 'Probar con otro RUT',
    CONNECT_ASSESSMENT_FORM_RUT_PLACEHOLDER: '12.345.678-9',
    CONNECT_ASSESSMENT_FORM_RUT_TEXT: 'RUT',
    CONNECT_ASSESSMENT_FORM_SERIAL_NUMBER_PLACEHOLDER: '123.123.123',
    CONNECT_ASSESSMENT_FORM_SERIAL_NUMBER_TEXT: 'NÚMERO DE SERIE CÉDULA DE IDENTIDAD',
    CONNECT_ASSESSMENT_FORM_TERMS: 'Términos y condiciones',
    CONNECT_ASSESSMENT_FORM_TERMS_LINK:
      '/static/site/content/infoComplementaria/bases/Terminos-y-condiciones-cotizar-telefonia.pdf',
    CONNECT_ASSESSMENT_FORM_TITLE: 'Datos para la evaluación',
    CONNECT_CLICK_TO_GET_CALLED_BUTTON_THIRD_MODULE_DESKTOP: '¡Te llamamos!',
    CONNECT_CLICK_TO_GET_CALLED_BUTTON_THIRD_MODULE_MOBILE: '¡Venta Telefonica!',
    CONNECT_CLICK_TO_GET_CALLED_END_TIME: '21',
    CONNECT_CLICK_TO_GET_CALLED_SERVICE_ERROR: '¡Ha ocurrido un error vuelve a intentarlo mas tarde!',
    CONNECT_CLICK_TO_GET_CALLED_SERVICE_REQUEST_CALL:
      'https://api-falabella-connect.buffetcloud.io/test/credito/bff/web/solicita/llamada',
    CONNECT_CLICK_TO_GET_CALLED_SERVICE_TOKEN:
      'https://api-falabella-connect.buffetcloud.io/test/autentia/bff/web/getToken',
    CONNECT_CLICK_TO_GET_CALLED_START_TIME: '18',
    CONNECT_CLICK_TO_GET_CALLED_UNAVAILABLE_TIME:
      'Nuestros ejecutivos no están disponibles en este momento. Te contactaremos próximamente para que puedas escoger el mejor plan para ti.',
    CONNECT_OPERATORS_OPTION: 'Selecciona un operador',
    CONNECT_PLANS_LABEL: 'Planes',
    CONNECT_PORTABILITY_REMINDER: 'Si te quieres portar, debes ser el títular de tú número',
    CONNECT_QUESTION_THIRD_MODULE: '¿Tienes dudas?',
    CONNECT_TEXT_THIRD_MODULE:
      'Si necesitas asistencia de un especialista ingresa tus datos de contacto y te llamaremos dentro de unos minutos. Recuerda que también puedes comunicarte directamente llamando al 2 2390 6534',
    CONTACT_OUR_SPECIALISTS: 'Te asesoramos por chat o teléfono',
    CONTRACT_PLAN_REBRANDING: 'Contratar plan',
    DELIVERY_TYPE_MOB_SUB: 'Compra online o compra en tienda',
    DELIVERYSLOT_CALENDAR_TIME_RANGE: 'Rango horario',
    DESPACHO: 'Despacho',
    DY_ALLOWED_SIS: 'sodimac',
    EMPTY_SEARCH_ERROR_MESSAGE: 'INGRESA LA PALABRA, PRODUCTO O CÓDIGO DE LO QUE QUIERES BUSCAR',
    EMPTY_SEARCH_ERROR_TITLE: 'Busqueda Vacia',
    ENTER_VALID_PRICE_ERROR_LABEL: 'Ingresa un rango de precios válido.',
    ENVIO_GRATIS_LABEL: 'En productos pequeños desde',
    ENVIO_GRATIS_LABEL_AMOUNT: 'S/ 99',
    ERROR_NO_OFFER_PER_OPERATOR:
      'No existen ofertas disponibles con este operador en la actualidad. ¡Revisa la oferta con otros operadores!',
    EURSIZE: 'EUR',
    EVALUATE_PLANS_BUTTON: 'EVALUAR PLANES PARA MÍ',
    EVALUATE_PLANS_BUTTON_REBRANDING: 'Revisar planes',
    EXPERT_ADVICE: 'ASESORÍA DE EXPERTOS',
    EXTENDED_WARRANTY_LABEL: 'Garantía extendida',
    FAILED_ADDING_TO_CART_LABEL: '¡Qué mal! Justo se agotó el producto. Busca otros similares.',
    FAILED_ADDING_TO_CART_SERVICE:
      'En este momento no podemos agregar el Servicio adicional seleccionado. Por favor inténtalo nuevamente más tarde.',
    FAILED_ADDING_TO_CART_WARRANTY:
      'En este momento no podemos agregar la opción de Garantía extendida. Por favor inténtalo nuevamente más tarde.',
    FALLBACK_SEO_BRAND_DESCRIPTION:
      'Compra online artículos y productos de $xlp_name$ conoce las diferentes opciones que tenemos para ti en tus marcas favoritas.',
    FALLBACK_SEO_BRAND_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_BRAND_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_BRAND_TITLE: '$xlp_name$',
    FALLBACK_SEO_BRAND_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    FALLBACK_SEO_BRAND_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    FALLBACK_SEO_CATEGORY_DESCRIPTION:
      'Encuentra $xlp_name$ de las mejores marcas en falabella.com, conoce la variedad de $xlp_name$ y elige el precio ideal para ti.',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_BRAND:
      'Adquiere $xlp_name$ $facet_value$ de manera segura y fácil en falabella.com Conoce nuestro catálogo online y haz la mejor elección.',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_CATEGORY_DESCRIPTION_TYPE:
      'Encuentra $facet_value$ en $xlp_name$ a precios increíbles, realiza tus compras online de manera segura en falabella.com',
    FALLBACK_SEO_CATEGORY_TITLE: '$xlp_name$ grandes ofertas',
    FALLBACK_SEO_CATEGORY_TITLE_BRAND: '$xlp_name$ $facet_value$',
    FALLBACK_SEO_CATEGORY_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    FALLBACK_SEO_CATEGORY_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    FALLBACK_SEO_CATEGORY_TITLE_TYPE: '$facet_value$ en $xlp_name$',
    FALLBACK_SEO_COLLECTION_DESCRIPTION:
      'Encuentra $xlp_name$ de tu marca favorita en falabella.com. Compra online con descuentos y envios gratis en productos seleccionados.',
    FALLBACK_SEO_COLLECTION_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_COLLECTION_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_COLLECTION_TITLE: '$xlp_name$',
    FALLBACK_SEO_COLLECTION_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    FALLBACK_SEO_COLLECTION_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    FALLBACK_SEO_SELLER_DESCRIPTION:
      'Conoce los productos de $xlp_name$. Entra y aprovecha los precios que tenemos en tus productos favoritos. Encuéntralos en falabella.com',
    FALLBACK_SEO_SELLER_DESCRIPTION_FACETS_1:
      '$store$ tiene para ti $xlp_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_SELLER_DESCRIPTION_FACETS_2:
      '$store$ tiene para ti $xlp_name$ $facet_name$ $facet_value$ y mucho más. Haz tus compras más fácil y seguro, todo un sólo lugar.',
    FALLBACK_SEO_SELLER_TITLE: '$xlp_name$',
    FALLBACK_SEO_SELLER_TITLE_FACETS_1: '$xlp_name$ $facet_value$ | $store$',
    FALLBACK_SEO_SELLER_TITLE_FACETS_2: '$xlp_name$ $facet_name$ $facet_value$ | $store$',
    FEATURE_FILTER_MODAL_TITLE: 'Selecciona un filtro',
    FILTERS_AVAILABILITY_TITLE_MOBILE: 'Entrega',
    FILTERS_TITLE: 'Filtrar',
    FILTERS_TITLE_MOBILE: 'Filtrar',
    FILTERS_TITLE_REBRANDING_MOBILE: 'Filtrar por:',
    FREE_SHIPPING_PRICE: '99',
    FREE_SHIPPING_PRICE_3P: '99',
    FREE_SHIPPING_SIZES: 'xxs,XXS,xs,XS,xs2,XS2,xs3,XS3',
    G_CATEGORIES_TO_HIDE_RETURN_POLICY: 'G080207,G080209',
    HELP: 'ayuda',
    HOME: 'Home',
    HOME_DELI_AVAILABLE_RESULT: 'Disponibilidad de fechas y horarios:',
    HOME_DELI_DATE_RANGE: 'Opción rango de fechas:',
    HOME_DELI_OUTOFSTOCK: 'No disponible para despacho a domicilio',
    HOME_DELI_RESULT_WILL_COME_HERE: 'Tus fechas más cercanas aparecerán aquí',
    HOME_DELI_SAVE: 'GUARDAR DIRECCIÓN',
    HOME_DELI_SEE_AVAILABILITY: 'Ver envío',
    HOME_DELI_STORE_PLEASE_SELECT: 'Revisar disponibilidad',
    HOME_DELI_SUB_PREFIX: 'A',
    HOME_DELI_TITLE: 'Despacho a domicilio',
    HOME_DELIVERY_SHIPPING: 'Despacho a Domicilio',
    HOME_SHIPPING: 'Envío a domicilio',
    IDEAL_PLAN_LABEL: 'Tenemos tu plan ideal',
    INCORRECT_SEARCH_ERROR_MESSAGE: 'INGRESA LA PALABRA, PRODUCTO O CÓDIGO DE LO QUE QUIERES BUSCAR',
    INCORRECT_SEARCH_ERROR_TITLE: 'Busqueda Incorrecta',
    INTERNATIONAL_DELIVERY: 'Compra internacional.',
    INTERNATIONAL_SHIPPING: 'Envío Internacional',
    INTERNATIONAL_SHIPPING_BULLET_TITLE: 'Notificaciones importantes',
    INTERNATIONAL_SHIPPING_BULLETS:
      'El vendedor envíe tu producto desde el país de origen.*Tu producto llegue a Perú.*Tu producto esté en camino a tu domicilio.',
    INTERNATIONAL_SHIPPING_BULLETS_1:
      'Podrás realizar un máximo de 3 importaciones al año con tu DNI. A partir de la cuarta compra, podrás gestionar la importación con tu RUC.*El precio publicado incluye el envío internacional y los impuestos de aduanas.*Los productos de compra internacional no se pueden devolver o cambiar si te arrepientes de la compra.',
    INTERNATIONAL_SHIPPING_FOOTER: 'Para más información, revisa nuestro',
    INTERNATIONAL_SHIPPING_LINK: 'https://www.falabella.com.pe/falabella-pe/page/compra-internacional',
    INTERNATIONAL_SHIPPING_PARA_1_DESC_BOLD: '',
    INTERNATIONAL_SHIPPING_PARA_1_DESC_LIGHT: '',
    INTERNATIONAL_SHIPPING_XLP: 'Compra internacional',
    IOS_FACET_SUPPORT_VERSION: '10.0.0',
    ITEM_ADDED_A11Y_LABEL: 'Item Added',
    ITEM_ADDED_IN_CART: 'Producto(s) agregado(s) al carro',
    LOADER_MESSAGE: 'Estamos procesando tu solicitud. Por favor espera un momento',
    LOCATION_DIALOG_BODY: 'Selecciona la región donde quieres ver la disponibilidad de productos.',
    LOCATION_DIALOG_HEADER: 'Cambia tu región',
    LOG_OUT_LABEL: 'Cerrar sesión',
    LOGIN: 'Inicia sesión',
    LOGIN_EMAIL_PLACEHOLDER: 'Ingresa tu e-mail',
    LOGIN_EMPTY_EMAIL: 'Por favor ingresa tu e-mail.',
    LOGIN_FACEBOOK: 'Ingresa Con Facebook',
    LOGIN_FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
    LOGIN_HEADER_DESC: 'Compra más rápido y revisa los detalles de tus órdenes',
    LOGIN_INVALID_EMAIL: 'Formato de email inválido nombre@ejemplo.com',
    LOGIN_INVALID_PASSWORD: 'Formato de contraseña inválido',
    LOGIN_LABEL: 'Inicia Sesión',
    LOGIN_LOG_IN: 'Iniciar sesión',
    LOGIN_NOT_REGISTERED: '¿No tienes cuenta?',
    LOGIN_OR: 'ó',
    LOGIN_PASSWORD_PLACEHOLDER: 'Ingresa tu contraseña',
    LOGIN_SIGN_UP: 'Regístrate',
    LOW_STOCK_WARNING: 'Los productos de tu carro de compras pueden agotarse próximamente. Cómpralos pronto.',
    MAX_FACET_LIMIT: 1500,
    MAXIMUM_QUANTITY_LIMIT_LABEL: 'La cantidad ingresada excede el stock del producto.',
    MENU: 'Menu',
    MODEL_CHARACTERISTICS_PREFIX: 'Medidas de modelo',
    MODEL_CHARACTERISTICS_PREFIX_MOBILE: 'Medidas de modelo',
    MONTHLY_PLAN_LABEL: 'Plan mensual desde',
    MORE_PRODUCTS_FROM_COLLECTION: 'Más productos de esta colección',
    MY_ACCOUNT: 'Mis ordenes',
    MY_ACCOUNT_LABEL: 'Mi Cuenta',
    MY_ORDERS: 'Mis órdenes',
    MY_ORDERS_DESC: 'Aquí podrás revisar el estado de tus órdenes.',
    NEED_HELP: '¿Necesitas ayuda?',
    NEED_HELP_CALL_US_AT: '¿Necesitas ayuda? Llámanos al',
    NEXT: 'Siguiente',
    NO_ACCOUNT_LABEL: '¿No tienes cuenta?',
    NO_PLAN_LABEL:
      'Por ahora no contamos con planes disponibles para este teléfono. Te recomendamos revisar otros teléfonos para que elijas el que más te guste y lo puedas llevar con el plan de tu preferencia.',
    NO_PLAN_TITLE: '¡Lo sentimos!',
    NO_RESULT_LABEL: 'Lo sentimos, no encontramos resultados para',
    OF: 'de',
    OFFER_PRICE_LABEL: '',
    OK_TO_SHOP_LABEL: 'Ficha obtenida de',
    ORDER_BY: 'Ordenar',
    ORDER_BY_DESKTOP: 'Ordenar por',
    ORDER_BY_MOBILE: 'Ordenar',
    OTHER_SEARCHES_LABEL: 'Otras posibles búsquedas:',
    OWN_INT_SELLER_INFO:
      "Este producto está publicado directamente por {{tienda}} y cuenta. Si al recibirlo no es lo que esperabas, te devolvemos tu dinero.<br/><br/><b style='font-weight: bold'>El precio del producto incluye el costo de envío internacional y pago de impuestos de internación</b> (aduana). Al ser internacional pasa por una inspección y, en algunos casos, esto podría generar atrasos en la entrega.",
    OWN_SELLER_INFO:
      "Producto publicado directamente por <b style='font-weight: bold; text-transform: capitalize;'>{{tienda}}</b>.",
    PAGE_ERROR_TITLE:
      'Lo sentimos, estamos presentando problemas técnicos. Por favor usa la navegación por categorías.',
    PAGE_TITLE: 'Falabella.com - Mejor Compra Online',
    PAGES_LABEL: 'Páginas',
    PAY_AT_BOX: 'PAGAR EN LA CAJA',
    PER_UNIT_PRICE_PREFIX: 'Unidad a',
    PERFUME_MERCHANT_CATEGORY_IDS: 'J08020101,J08020102,J08050101',
    PICKUP_INSTORE_AVAILABLE_RESULT: 'Fechas y horarios de despacho disponibles',
    PICKUP_INSTORE_AVAILABLEFROM: 'Disponible a partir',
    PICKUP_INSTORE_CLOSE_BUTTON: 'CERRAR',
    PICKUP_INSTORE_INFO_CAN_VARY: 'La información de disponibilidad puede variar durante la compra.',
    PICKUP_INSTORE_NO_RESULT_AVAILABLE: 'Lo sentimos, no se encontraron tiendas disponibles',
    PICKUP_INSTORE_OUTOFSTOCK: 'No disponible para retiro',
    PICKUP_INSTORE_PLEASE_SELECT: 'Selecciona una tienda',
    PICKUP_INSTORE_RESULT_WILL_COME_HERE: 'Tus tienda más cercanas aparecerán aquí',
    PICKUP_INSTORE_SAVESTORE_BUTTON: 'GUARDAR TIENDA',
    PICKUP_INSTORE_SEE_AVAILABILITY: 'Ver retiro',
    PICKUP_INSTORE_STORES_AVAILABLE: 'Tiendas disponibles',
    PICKUP_INSTORE_SUB_PREFIX: 'En',
    PICKUP_INSTORE_TITLE: 'Retira tu compra',
    PLAN_REVIEW_LABEL:
      'Revisa los planes disponibles de los distintos operadores quetenemos para ti con este teléfono.',
    PLANES: 'PLANES',
    PLANS_CARD_ONLINE: 'ONLINE',
    PLANS_CARD_SUBTITLE: 'Ingresa tus datos y descubre las mejores ofertas para ti. (Necesitas tu cédula de identidad)',
    PLANS_CARD_SUBTITLE_MODAL: 'Ingresa tus datos y encuentra las mejores ofertas para ti, con todas las compañias.',
    PLANS_CARD_TITLE: 'CONTRATA TU PLAN',
    PLANS_CLICK_TO_GET_CALLED_BUTTON_COMUNICATE: 'COMUNÍCATE AHORA',
    PLANS_CLICK_TO_GET_CALLED_BUTTON_REBRANDING: 'Comunícate ahora',
    PLANS_CLICK_TO_GET_CALLED_FORM_INPUT_NUMBER: '+56',
    PLANS_CLICK_TO_GET_CALLED_FORM_NAME: 'Nombre',
    PLANS_CLICK_TO_GET_CALLED_FORM_NUMBER: 'Número de contacto',
    PLANS_CLICK_TO_GET_CALLED_FORM_PH_NAME: 'JUANA RÍOS',
    PLANS_CLICK_TO_GET_CALLED_FORM_PH_NUMBER: '987654321',
    PLANS_CLICK_TO_GET_CALLED_NUMBER: 'O llámanos al 2 2390 6534',
    PLANS_CLICK_TO_GET_CALLED_OK_SUBTITLE:
      'En minutos un ejecutivo estará contactándose para que puedas escoger el mejor plan para ti.',
    PLANS_CLICK_TO_GET_CALLED_OK_TITLE_END: 'recibimos tu solicitud!',
    PLANS_CLICK_TO_GET_CALLED_OK_TITLE_SIGN: '¡',
    PLANS_CLICK_TO_GET_CALLED_SUBTITLE:
      'Si necesitas asistencia de un especialista ingresa tus datos de contacto y te llamaremos dentro de unos minutos.',
    PLANS_CLICK_TO_GET_CALLED_TITLE: '¡No te quedes sin tu plan!',
    PLANS_DETAILS_FREE_MINUTES_LABEL: 'MINUTOS LIBRES',
    PLANS_DETAILS_GB_LABEL: 'GB',
    PLANS_DETAILS_MINUTES_LABEL: 'MINUTOS',
    PLANS_DETAILS_MONTH_LABEL: ' meses',
    PLANS_DETAILS_MONTHLY_COST_LABEL: 'Cargo fijo mensual',
    PLANS_DETAILS_NEED_EVALUATION_LABEL: 'DEBES EVALUARTE',
    PLANS_DETAILS_PRICE_LABEL: 'Valor equipo',
    POD_BOOM_SHIPPING_LABEL: 'Boom',
    PREPAID_DEVICE: 'Equipo prepago desde',
    PREPAID_OPTION: 'Ver la opción prepago',
    PREVIOUS: 'Anterior',
    PRICE_NO_RESULTS_ERROR_LABEL: 'Rango sin resultados. Por favor ingresa otros valores.',
    PRICE_RANGE_FILTER_BUTTON: 'Filtrar',
    PRICE_RANGE_FROM: 'Desde',
    PRICE_RANGE_TO: 'Hasta',
    product: 'Product',
    PRODUCT_ADDED: 'Producto(s) agregado',
    PRODUCT_CODE: 'Código',
    PRODUCT_INFORMATION_LABEL: 'Información adicional',
    PRODUCT_OUTOFSTOCK: 'Producto sin stock :(',
    PRODUCT_OUTOFSTOCK_TRY_OTHERS: 'Prueba buscando otros productos en',
    PRODUCT_WITHOUT_RESULT_TITLE: 'El producto que estás buscando ya no está disponible.',
    PROMOTED_FILTER_FOR: 'Filtrar por ',
    PUM_AFFIX: ' a ',
    PURCHASE_ONLINE_OUTOFSTOCK: 'No Disponible para compra en internet',
    PURCHASE_ONLINE_TITLE: 'Disponible para compra en internet',
    RATINGS_STAR_MORE_TEXT: 'y más',
    RATINGS_STAR_ONLY_TEXT: 'solamente',
    REACONDITIONED_LINK:
      'https://assets.contentstack.io/v3/assets/blt7c5c2f2f888a7cc3/blt6e6552e1707e37fd/productos-reacondicionados.pdf',
    READY: 'Aplicar',
    RECOMMENDED_PRODUCT_LABEL: 'Clientes que vieron este producto tambieñ vieron',
    REGION: 'Departamento',
    REGIONS: 'Regiones',
    REGISTER_LABEL: 'Registrate',
    relatedBrands: 'MARCAS RELACIONADAS',
    relatedCategories: 'CATEGORÍAS RELACIONADAS',
    REQUEST_ACCEPTED: 'SOLICITUD ACEPTADA',
    REQUEST_ACCEPTED_MSG_1: 'Favor retirar sus productos en trastienda',
    REQUEST_PRODUCT: 'Solicitar Producto',
    RESULTS: 'Resultados',
    RETURN_POLICY_HEADING: 'Devolver es fácil y gratis',
    RETURN_POLICY_INFO_HARDLINE: 'Para cambios o devoluciones.',
    RETURN_POLICY_INFO_SHOES: 'Para cambios o devoluciones gratis en cualquier tienda Falabella.',
    RETURN_POLICY_INFO_SOFTLINE: 'Para cambios o devoluciones gratis en cualquier tienda Falabella.',
    RETURN_POLICY_LINK_TEXT: 'Conoce nuestra Satisfacción garantizada',
    RETURN_POLICY_LIST_2:
      '<b style="font-weight: bold">48 horas</b>: cemento, mezclas de: hormigón, morteros, yeso y otros productos para asfalto, hormigón, albañilería.*<b style="font-weight: bold">7 días</b>: electrodomésticos, tecnología, línea blanca, colchones, muebles, bicicletas y máquinas de ejercicio.',
    RETURN_POLICY_LIST_3:
      'Cuidado personal, belleza, higiene y salud, productos de bienestar sexual.​​*Trajes de baño, fajas, ropa interior.​​​*Electrónica.​​​*Electro hogar.​​*Almohadas y ropa de cama.​*Muebles armables y pinturas.​​​*Alimentos y cuidado de mascotas.​​​*Productos de limpieza (PGC no comestible).​*Asiento de inodoro.*Toallas de baño y playa.',
    RETURN_POLICY_LIST_4:
      'Alimentos y bebidas. ​*Productos digitales (descarga inmediata).​​​*De segunda mano o reacondicionados.​​​*Productos hechos a medida.​​​*Pinturas color a pedido.​​​*Medicamentos, vitaminas y suplementos.​​​*Plantas​​.*Productos que hayan sido previamente instalados.*Baterías de auto.',
    RETURN_POLICY_MOBILE_HEADER: 'Politica de cambios y devoluciones',
    RETURN_POLICY_MODAL_FOOTER: 'Conoce más sobre',
    RETURN_POLICY_MODAL_FOOTER_UNDERLINE_TEXT: 'devoluciones y cambios',
    RETURN_POLICY_MODAL_HEADER_1: 'Satisfacción garantizada',
    RETURN_POLICY_PARA_1_1:
      'La mayoría de los productos tienen <b style="font-weight: bold">30 días desde que los recibes para hacer una devolución.</b>',
    RETURN_POLICY_PARA_1_2:
      'Sin embargo, tenemos categorías que cuentan con plazos diferentes, otras con restricciones y algunas que no se pueden devolver ni cambiar. Conoce cuáles son:',
    RETURN_POLICY_PARA_2_1: '<b style="font-weight: bold">Otros plazos para devolución y cambio</b>',
    RETURN_POLICY_PARA_3_1: '<b style="font-weight: bold">Deben estar cerrados, con todos sus sellos y etiquetas</b>',
    RETURN_POLICY_PARA_4_1:
      '<b style="font-weight: bold">No tienen devolución o cambio si te arrepientes de la compra</b>',
    SAME_DAY_DELIVERY_LABEL: 'Si compras hasta las',
    SAME_DAY_DELIVERY_LABEL_AMOUNT: '16:00 horas',
    SAME_DAY_DELIVERY_TIMELINE: 'llega hoy',
    SAVE: 'Guardar',
    SEARCH_IN_FACETS_LABEL: 'search in facets',
    SEARCH_RESULTS_FOR: 'Resultados de la búsqueda',
    SEARCH_WITHOUT_RESULT_DESCRIPTION:
      'Tal vez su búsqueda fue demasiado específica, intente buscar con un término más general.',
    SEARCH_WITHOUT_RESULT_TITLE: 'Lo sentimos, no encontramos resultados para',
    searchPlaceholder: '¿Qué buscas?',
    SEE_COLLECTION_BUTTON_LABEL: 'VER COLECCIÓN',
    SEE_COMMENTS: 'Ver más comentarios',
    SEE_LESS: 'ver menos',
    SEE_LESS_FACET_VALUES: '+ Ver menos',
    SEE_MORE: 'ver más',
    SEE_MORE_FACET_VALUES: '+ Ver más',
    SELECT_OPERATOR_ALERT_LABEL: 'El precio puede variar según Chip prepago y/o color',
    SELECT_OPERATOR_LABEL: 'Selecciona Chip prepago (incluido):',
    SELECT_VARIANT_CHOOSE_OPTIONS: 'ELIGE TUS OPCIONES',
    SELECT_VARIANT_CHOOSE_OPTIONS_REBRANDING: 'Elige tus opciones',
    SELECT_VARIANT_WARNING_MESSAGE: 'Selecciona tus opciones para agregar el producto a la bolsa',
    SELECTED_FILTERS: 'Filtro seleccionado',
    SELECTED_FILTERS_MOBILE: 'Filtros:',
    SELECTION_SIZE: 'Selecciona Talla',
    SELLER_AND_SHIPPED_BY: 'y enviado por',
    SELLER_NAMES_EXCLUDED_FOR_FREE_SHIPPING: 'falabella, tottus, sodimac, linio',
    SELLER_SKU_ID: 'Cód. tienda',
    SELLER_SOLD_BY: 'Vendido por',
    sellerMuteValue: 'LINIO',
    SHARE_FIND_IN: 'Encuéntralo en',
    SHARE_I_WANT: '¡Lo quiero!',
    SHARE_LIKE_PRODUCT: 'Me gustó este producto',
    SHIPPING_LABELS: 'Llega hoy,Llega mañana,Envío gratis',
    SHOW_OFFER_BUTTON: 'VER TODA LA OFERTA',
    SHOW_OFFER_BUTTON_REBRANDING: 'Ver toda la oferta',
    SHOWING_RESULT_FOR_LABEL: 'Mostrando resultados para',
    SIZE: 'TALLA',
    SIZE_CHART: 'Tabla de tallas',
    SIZE_CHART_NAME: 'TABLA DE TALLAS',
    SKU_SEARCH_REGEX:
      '(^[0-9]{5,})+$|(^prod[0-9]+$)|(^sku[0-9]+$)|(^[0-9]{4,}X+$)|(^[0-9]{7,})+C$|C[0-9]{1,2}$|(^[0-9]{3,6})+XC$|XC[0-9]$',
    SLP_BI_AB_COOKIE: 'search_ab',
    SPECIFICATIONS_LABEL: 'Especificaciones',
    SPECIFY_YOUR_SEARCH_LABEL: 'Especifica tu búsqueda:',
    SPONSORED_PRODUCT_SLOTS: '[0, 10.9, 50, 100]',
    STOCK: 'STOCK',
    STORE_PICKUP_SAVE: 'GUARDAR TIENDA',
    STORES_WITH_STOCK_IN_OTHER_COMUNA: 'tiendas con stock en otros distritos',
    SUBSCRIPTION_ACCEPT_TERMS: 'Debes aceptar los terminos y condiciones',
    SUBSCRIPTION_EMPTY_ERROR: 'Por favor ingresa un mail',
    SUBSCRIPTION_INVALID_EMAIL_ERROR: 'Debes ingresar un mail valido',
    SURVEY_TEXT_ANY_MISTAKE: '¿Encontraste algún error?',
    SURVEY_TEXT_HELP_US: 'Ayúdanos a mejorar tu experiencia',
    SURVEY_TEXT_TELL_US: '¡Cuéntanos!',
    TEAM: 'EQUIPO',
    TEAM_FROM_LABEL: 'Equipo desde',
    THIRD_PARTY_INT_SELLER_INFO:
      "Este producto está publicado directamente por {{tienda}} y cuenta. Si al recibirlo no es lo que esperabas, te devolvemos tu dinero.<br/><br/><b style='font-weight: bold'>El precio del producto incluye el costo de envío internacional y pago de impuestos de internación</b> (aduana). Al ser internacional pasa por una inspección y, en algunos casos, esto podría generar atrasos en la entrega.",
    THIRD_PARTY_SELLER_INFO:
      'Este producto está publicado directamente por un vendedor nacional y cuenta. Si al recibirlo no es lo que esperabas, te devolvemos tu dinero.',
    TOGETHER_PRODUCTS: 'Van bien juntos',
    TOP_SPECIFICATIONS: 'Especificaciones principales',
    TRACK_ORDER: 'Seguimiento de mi orden',
    TYPE_C_HOME_DELI_OUTOFSTOCK: 'No disponible para despacho a domicilio',
    TYPE_C_HOME_DELI_TITLE: 'Disponible para despacho a domicilio',
    TYPE_C_PICKUP_INSTORE_OUTOFSTOCK: 'No disponible para retiro en tienda',
    TYPE_C_PICKUP_INSTORE_TITLE: 'Disponible para retiro en tienda',
    UKSIZE: 'UK',
    UNITS_AVAILABLE_LABEL: 'Unidades disponibles',
    USSIZE: 'US',
    VENDER_CANCEL_BUTTON_LABEL: 'CANCELAR',
    VENDOR_BARCODE_CENTER_LABEL: 'Centra tu código de barras y evita mover tu teléfono.',
    VENDOR_CLIENT_LABEL: 'Cliente',
    VENDOR_HOME_LABEL: 'VENDOR HOME',
    VENDOR_INFORMATION_NOT_AVAILABLE_LABEL: 'Informacion no disponible',
    VENDOR_INVALID_CODE_LABEL: 'Lo sentimos, no hemos podido encontrar el producto escaneado.',
    VENDOR_LABEL: 'Vendedor',
    VENDOR_LOADING_LABEL: 'Buscando..',
    VENDOR_LOGOUT_LABEL: 'Cerrar sesión',
    VENDOR_NEW_CUSTOMER_SEARCH_LABEL: 'Buscar nuevo cliente',
    VENDOR_PRICE_LABEL: 'Precio',
    VENDOR_PRODUCT_AVAILABLE_LABEL: 'Producto disponible en tienda',
    VENDOR_SCAN_ANOTHER_PRODUCT_LABEL: 'ESCANEAR OTRO PRODUCTO',
    VENDOR_SCAN_INFO_LABEL: 'El escaneo es automático, evita brillos y reflejos.',
    VENDOR_SCAN_LABEL: 'Scan',
    VENDOR_STOCK_LABEL: 'Stock',
    VENDOR_STORE_LABEL: 'Tienda',
    VIEW_MORE_SPECIFICATONS: 'Ver más especificaciones',
    VIEW_PLAN: 'Ver Plan',
    VIEW_PLANES_LABEL: 'VER PLANES',
    VIEW_PREPAID: 'Ver Prepago',
    VIEW_RESULTS_FACET_MODAL: 'Ver resultados',
    WANT_THIS_PLAN_BUTTON: 'QUIERO ESTE PLAN',
    WANT_THIS_PLAN_BUTTON_REBRANDING: 'Quiero este plan',
    WARNING_A11Y_LABEL: 'Warning',
    WELCOME_LABEL: 'Bienvenid@,',
    WHY_PDP: '¿Por qué?',
    WITHDRAW_AT_STORE_LABEL_AMOUNT: '16:00 horas',
    WITHDRAWAL_AT_STORE: 'Retiro en un punto',
    WITHDRAWAL_AT_STORE_LABEL: 'Si compras hasta las',
    WITHDRAWAL_AT_STORE_TIMELINE: 'retira hoy',
  },
  toggles: {
    adInjectorIsEnabled: false,
    disableGeofinderModalForMobile: true,
    disableQuantitySelection: false,
    disableSponsoredProductsPDP: false,
    disableSponsoredProductsSisPDP: true,
    featureEnableSponsoredDisplay: true,
    freeShippingOnZonesEnabled: true,
    indexAndCrawlSLTPagesToggle: true,
    is3PFreeShippingEnabled: false,
    isAbCookieEnabledBrand: false,
    isAbCookieEnabledCLP: false,
    isAbCookieEnabledPLP: false,
    isAbCookieEnabledSeller: false,
    isAbCookieEnabledSLP: false,
    isAbtastyEnabled: 'true',
    isActiveTabAssistentePersonal: false,
    isAdditionalServiceFilteredForSodimac: false,
    isAddtionalInfoForSodimacEnabledInUI: true,
    isAddtionalSellerTextEnabled: true,
    isBoomVisibleInFacet: false,
    isBoomVisibleInPod: false,
    isBreadcrumbsV1Enabled: false,
    isBreadcrumbsV2Enabled: true,
    isBVQuestionsEnabled: false,
    isCartQuantityLimitsEnabled: false,
    isCatalogV2EndpointActive: true,
    isCategoryFacetConfiguredAsGlobalFacet: true,
    isCFMediasEnabled: false,
    isChatBotVisible: false,
    isCMRAperturaEnabled: true,
    isCMRAperturaInNewPageEnabled: true,
    isCMRButtonWithPriceVisibleInPDP: true,
    isCMRCalculatorEnabledPDP: false,
    isComboUiEnabled: true,
    isComparativeTableEnabled: true,
    isCustomABTestFlagEnabled: true,
    isDYEnabled: true,
    isEventToggleVisible: false,
    isFacetCountHidden: true,
    isFacetWithSwitchVisible: true,
    isFastShippingTextEnabled: false,
    isFloorcalculatorEnabled: false,
    isGenericCalculatorEnabled: true,
    isGeoLocationEnabled: true,
    isGlobalCategoryEnabled: true,
    isHelplineEnabled: true,
    isHomeDeliveryVisibleInFacet: false,
    isHomeDeliveryVisibleInPDP: true,
    isHomeDeliveryVisibleInPod: true,
    isHtmlValidatorEnabled: false,
    isInHouseReviewSectionEnabled: true,
    isInternationalBuyVisiblePDP: true,
    isInternationalShippingVisibleInPod: true,
    isMeatStickerApplicable: true,
    isMeatStickerApplicableInPDP: true,
    isMKPProductDisabled: false,
    isMultipurposeBadgeApplicable: true,
    isMyListsEnabled: true,
    isNewBVWriteReviewEnabled: 'true',
    isNewDeliveryOptionsEnabled: true,
    isNewPriceApplicable: true,
    isNewSortingLogicForSponsoredProductsEnabled: false,
    isNewTypeaheadEnabled: true,
    isNewTypeaheadEnabledForBeta: true,
    isNewWarrantyOptionsEnabled: true,
    isNewWarrantyOptionsV2Enabled: true,
    isNewWarrantyUIEnabled: true,
    isNormalPriceCrossedOut: true,
    isPDPMyListsEnabled: true,
    isPDPShippingModalEnabled: true,
    isPDPWidgetGlobalEnabled: true,
    isPickUpFromStoreVisibleInFacet: false,
    isPickUpFromStoreVisibleInPod: true,
    isPickupInStoreVisibleInPDP: true,
    isPictureTagEnabled: true,
    isPlansClickToGetCalledEnabled: true,
    isPlansEvaluationEnabled: true,
    isPodActionButtonEnabled: true,
    isPoliticalIdExists: true,
    isPopOverVisible: true,
    isPromotionsEnabledInPDP: true,
    isPumPriceEnabled: false,
    isPurchaseOnlineVisibleInPDP: false,
    isQuantityEditableEnabled: true,
    isQuantityEdittableEnabled: true,
    isQuantitySelectorVisibleInPDP: true,
    isReaconditionedLinkEnabledPDP: true,
    isRecoV2Enabled: true,
    isRedirect301EnabledForBrand: true,
    isRedirect301EnabledForCollection: false,
    isRedirect301EnabledForPLP: true,
    isRelatedKeywordEnabled: false,
    isRelevancyForCollection: true,
    isReturnPolicyVisibleInPDP: true,
    isSellerBusinessNameEnabled: true,
    isSellerInfoVisibleInPDP: true,
    isSellerMute: true,
    isSellerPopupEnabled: true,
    isSellerRucEnabled: true,
    isShippingFacetsWithFlattenedPAIVisible: false,
    isShippingFacetsWithZonesVisible: false,
    isShippingFacetVisible: false,
    isShippingLabels404LogsDisabled: true,
    isShippingLabelsEnabledInPdp: false,
    isShippingLabelsEnabledInXlp: false,
    isSISExperienceOn: true,
    isSisSodimacPdpEnabled: false,
    isStockQuantityVisibleInPDP: false,
    isStoreAvailabilityVisibleInPDP: true,
    isTypeAheadEnabled: true,
    isUnitForSaleEnabled: true,
    isVwoEnabled: 'true',
    isWarrantyAtProductLevel: true,
    isWarrantyTooltipEnabled: true,
    isWebPushNotificationEnabled: true,
    newDiscountBadge: true,
    searchWithFlattenedPAIdEnabled: false,
    searchWithPoliticalAreaIdEnabled: false,
    shouldSendContextIdToReco: true,
    shouldSendUserIdToReco: true,
    showCustomPlaceHolderForPriceRange: true,
    splitSearchFacetCalls: true,
    transversalBannerConfig: '{"home":true,"pdp":true,"xlp":true,"basket":true}',
    useImplicitFilterForXLP: false,
  },
};
