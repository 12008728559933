const svgToDataUri = (svgStr) => {
  const encoded = encodeURIComponent(svgStr).replace(/'/g, '%27').replace(/"/g, '%22');

  const header = 'data:image/svg+xml;charset=utf8,';
  const dataUrl = header + encoded;

  return dataUrl;
};

const dataUriToSvg = (dataUri) => {
  return decodeURIComponent(dataUri.replace(/^data:image\/svg\+xml;charset=utf-8,/, ''));
};

const svgReplaceColor = (svgStr, color) => {
  return svgStr.replace(/#xxxxxx/g, color);
};

export { dataUriToSvg, svgReplaceColor, svgToDataUri };
