import css from 'styled-jsx/css';
import { zIndex, typography } from '../../config/styles';

const loaderStyle = css`
  .loader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: ${zIndex.loader.overlay};
    font-size: ${typography.base};
    display: flex;
    background: linear-gradient(135deg, #fafafa, #fdfdfd);
    opacity: 0.6;
  }
`;

export { loaderStyle };
