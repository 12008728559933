import React, { useState, useContext } from 'react';
import createContainer from 'constate';

function useConfigurationContext({ config: initialConfig }) {
  const [config] = useState(initialConfig);
  return { config };
}

const ConfigurationContext = createContainer(useConfigurationContext);

const withConfig = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithConfig = (props) => {
    const config = useContext(ConfigurationContext.Context);
    return <WrappedComponent {...props} {...config} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithConfig.getInitialProps = getInitialProps;
  }
  return ComponentWithConfig;
};

export default ConfigurationContext;
export { withConfig };
