import createContainer from 'constate';
import React, { useContext, useState } from 'react';

function useGlobalDataContext({ taxonomy, header, footer }) {
  const [data] = useState({ taxonomy, header, footer });
  return { taxonomy: data.taxonomy, header: data.header, footer: data.footer };
}

const GlobalDataContext = createContainer(useGlobalDataContext);

const withGlobalDataContext = (keys) => (WrappedComponent) => {
  return (props) => {
    const globalData = useContext(GlobalDataContext.Context);
    const dataProps = {};
    keys.forEach((key) => {
      // eslint-disable-next-line react/destructuring-assignment
      if (globalData[key]) {
        // eslint-disable-next-line react/destructuring-assignment
        dataProps[key] = globalData[key];
      }
    });
    return <WrappedComponent {...props} {...dataProps} />;
  };
};

export default GlobalDataContext;
export { withGlobalDataContext };
