import zonesV1AR from './zones_v1_ar.json';
import zonesV1CL from './zones_v1_cl.json';
import zonesV1CO from './zones_v1_co.json';
import zonesV1PE from './zones_v1_pe.json';

export const geoFallback = {
  zones: {
    v1: {
      ar: zonesV1AR,
      cl: zonesV1CL,
      co: zonesV1CO,
      pe: zonesV1PE,
    },
  },
};
